<template>
  <div class="capabilityModel">
      <div class="capabilityModel_head">
          <img src="../assets/img/capabilityModel_bg1.png" alt="" style="width:100%;">
          <img class="modal_img" src="../assets/img/flymodal.png" alt="">
          <div class="position_head">
              <div>《青年能力素质成长飞轮》v2.0</div>
              <p>天才在线旗下青年人力资本发展研究中心构建了《青年能力素质成长飞轮》v1.0；以自我，与人、与事、与世界四个方面作为顶层维度，拆解为10个要素，41个能力点，供各高校参考 。</p>
          </div>
      </div>
      <div class="capabilityModel_content">
        <div v-for="(item,index) in menuDate" :key="index">
            <div class="content_tit">
              <!-- <img src="../assets/img/icon1.png" alt=""> -->
              <p class="dian"></p>
              <div>{{item.name}}</div>
              <div>{{item.english==''?'':item.english}}</div>
              <div>Code：{{item.code}}</div>
            </div>
            <div class="last_item" v-for="(item2,index) in item.son" :key='index' @click="handleChange(item2.id,1)">
            <el-collapse accordion v-model="activeName">
              <el-collapse-item :name="item2.id">
                <template slot="title">
                    <div class="content_item">
                      <div class="item_box">
                          <div class="shu"></div>
                         <div class="cont">{{item2.name}}<span>{{item2.english==''?'':item2.english}}</span></div>
                      </div>
                      <div class="cont2">Code：{{item2.code}}</div>
                    </div>
                </template>
                <div class="item_box2"  v-loading="loading">
                    <div>
                      <span>隶属：</span>{{item2.detail?item2.detail.ability_parent:''}}
                    </div>
                    <div>
                      <span>释意：</span>{{item2.detail?item2.detail.definition:''}}
                    </div>
                    <div class="item_tag_box">
                      <span>复合型</span>包含子能力
                      <div class="item_tag" v-if="item2.detail">
                          <div v-for="(item,index) in item2.detail.ability_children" :key="index">{{item}}</div>
                      </div>
                    </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'capabilityModel',
  data() {
      return {
        menuDate:[],
        id:0,
        active:0,
        box2Date:[],
        loading:true,
        activeName:0,
      };
    },
      mounted() {
    this.getMenu();
  },
    methods: {
    getMenu() {
      this.$api.common
        .menu()
        .then((res) => {
          this.menuDate=[];
          for(var i=0;i<res.data.length;i++){
            for(var j=0;j<res.data[i].son.length;j++){
                this.menuDate.push(res.data[i].son[j])
            } 
          }
        })
        .catch((err) => {
          
        });
    },
    handleChange(id,avtive) {
       this.loading=true;
        this.id=id;
        var params={id:this.id}
         this.$api.common
        .detail(params)
        .then((res) => {
           res.data.ability_parent=res.data.ability_parent.join('、')
          for(var i=0;i<this.menuDate.length;i++){
            for(var j=0;j<this.menuDate[i].son.length;j++){
              if(this.id==this.menuDate[i].son[j].id){
                 this.menuDate[i].son[j].detail=res.data;
              }     
            }
          }
          this.$forceUpdate();
          this.loading=false;
        })
        .catch((err) => {
          
        });
    }
    }
}
</script>
<style lang="scss" scoped>
.capabilityModel{
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 120px;
  .capabilityModel_head{
    margin-top: 30px;
    position: relative;
    margin-bottom: 30px;
    .modal_img{
      position: absolute;
      width: 350px;
      top: 44px;
      left: 100px;
    }
    .position_head{
      position: absolute;
      top: 134px;
      right: 119px;
      width: 457px;
      color: #fff;
      font-size: 15px;
      text-align: left;
      div:nth-child(1){
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 50px;
      }
      p{
        line-height: 30px;
      }
    }
  }
}
.capabilityModel_content{
  background: #fff;
  padding: 87px 107px 50px 50px;
  ::v-deep .el-collapse-item__header{
    background: #EEF9FC;
    height: 62px;
  }
  ::v-deep .el-collapse-item__wrap{
    background: #EEF9FC;
  }
  .content_item{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: #EEF9FC;
    .item_box{
      display: flex;
      align-items: center;
      .shu{
         width: 4px;
         height: 30px;
         background: #20A8D9;
         margin-right: 30px;
      }
      .cont{
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        span{
          font-size: 18px;
          color: #999999;
          margin-left: 20px;
        }
      }
    }
    .cont2{
        font-size: 18px;
          color: #999999;
          margin-right: 20px;
      }
  }
  ::v-deep .el-collapse-item__arrow{
        font-size: 20px;
    color: #7C829D;
  }
}
.item_box2{
   text-align: left;
   padding: 0 50px;
   font-size: 18px;
   color: #666666;
   span{
     font-size: 18px;
     font-weight: bold;
     color: #666666;
     width: 60px;
     margin-right: 28.5px;
     display: inline-block;
   }
   .item_tag{
     display: flex;
     align-items: center;
     margin-left: 28.5px;
     div{
       padding: 5px 9px;
       margin-right: 16px;
       border:.5px solid #20A8D9;
       border-radius: 5px;
       font-size: 18px;
       line-height: 20px;
       color: #20A8D9;
     }
   }
}
.item_tag_box{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.content_tit{
  display: flex;
  align-items: center;
  img{
    height: 46px;
    margin-right: 14px;
  }
  div:first-of-type{
     color: #20A8D9;
     font-size: 30px;
     font-weight: bold;
     margin-right: 24px;
  }
  div:nth-of-type(2){
    font-size: 18px;
    color: #999999;
    margin-right: 20px;
  }
  div:nth-of-type(3){
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    border-radius: 5px;
    background: #EEF9FC;
    color: #20A8D9;
    font-size: 18px;
  }
}
.content_tit{
  margin-bottom: 50px;
}
.last_item{
  margin-bottom: 50px;
}
.dian{
  width: 10px;
  height: 10px;
  background: #20A8D9;
  border-radius: 50%;
  margin-right: 10px;
}
</style>

